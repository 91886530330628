<template>
  <div style="background: white">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-container fluid id="sub-bar">
        <v-row>
          <v-col cols="11">
            <v-btn text @click="showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div id="lbl-screen-name">登録2</div>
          </v-col>
          <v-col cols="1">
            <v-btn text id="btn-logout" class="menu-btn" @click="logout">
              <img src="../../assets/img/logout_menu_icon.png" />ログアウト</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1"> XXX入力 </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2"> XXX入力 </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> 確認 </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container fluid>
              <!-- 登録項目 -->
              <v-form ref="registerForm">
                <!-- プルダウン1 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxxx1") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          ref="country"
                          v-model="xxxxxxxxx1Selected"
                          :items="dummy_xxx1List"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                  <!-- テキストボックス1 -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy1") }}
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy1"
                          maxlength="10"
                          placeholder="文字を入力してください1"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                  <!-- 日付 -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxDate") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          outlined
                          dense
                          v-model="arrivalScheduleDate"
                          class="txt-single"
                          :label="$t('label.lbl_arrivalScheduleDate')"
                          @change="changeDate"
                          :rules="[rules.yyyymmdd]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          :nudge-right="-100"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          class="date-calendar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" text
                              ><v-icon>mdi-calendar</v-icon></v-btn
                            >
                          </template>
                          <v-date-picker
                            v-model="arrivalScheduleDateCal"
                            @input="dateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- プルダウン2 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxxx2") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          v-model="xxxxxxxxx2Selected"
                          :items="dummy_xxx2List"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
                <!-- テキストボックス2 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy2") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy2"
                          maxlength="10"
                          placeholder="文字を入力してください2"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
                <!-- テキストボックス3 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy3") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy3"
                          maxlength="10"
                          placeholder="文字を入力してください3"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- ボタン -->
            <v-container>
              <v-row>
                <!--戻る -->
                <v-col>
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="e1 = 1">
                    {{ $t("btn.btn_back") }}
                  </v-btn>
                </v-col>
                <!--登録 -->
                <v-col>
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="e1 = 2">
                    {{ $t("btn.btn_insert") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-container fluid>
              <!-- 登録項目 -->
              <v-form ref="registerForm">
                <!-- プルダウン1 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxxx1") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          ref="country"
                          v-model="xxxxxxxxx1Selected"
                          :items="dummy_xxx1List"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                  <!-- テキストボックス1 -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy1") }}
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy1"
                          maxlength="10"
                          placeholder="文字を入力してください1"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                  <!-- 日付 -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxDate") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          outlined
                          dense
                          v-model="arrivalScheduleDate"
                          class="txt-single"
                          :label="$t('label.lbl_arrivalScheduleDate')"
                          @change="changeDate"
                          :rules="[rules.yyyymmdd]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          :nudge-right="-100"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          class="date-calendar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" text
                              ><v-icon>mdi-calendar</v-icon></v-btn
                            >
                          </template>
                          <v-date-picker
                            v-model="arrivalScheduleDateCal"
                            @input="dateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- プルダウン2 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxxx2") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          v-model="xxxxxxxxx2Selected"
                          :items="dummy_xxx2List"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
                <!-- テキストボックス2 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy2") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy2"
                          maxlength="10"
                          placeholder="文字を入力してください2"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
                <!-- テキストボックス3 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy3") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy3"
                          maxlength="10"
                          placeholder="文字を入力してください3"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- ボタン -->
            <v-container>
              <v-row>
                <!--戻る -->
                <v-col>
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="e1 = 2">
                    {{ $t("btn.btn_back") }}
                  </v-btn>
                </v-col>
                <!--登録 -->
                <v-col>
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="e1 = 3">
                    {{ $t("btn.btn_insert") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-container fluid>
              <!-- 登録項目 -->
              <v-form ref="registerForm">
                <!-- プルダウン1 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxxx1") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          ref="country"
                          v-model="xxxxxxxxx1Selected"
                          :items="dummy_xxx1List"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                  <!-- テキストボックス1 -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy1") }}
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy1"
                          maxlength="10"
                          placeholder="文字を入力してください1"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                  <!-- 日付 -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxDate") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          outlined
                          dense
                          v-model="arrivalScheduleDate"
                          class="txt-single"
                          :label="$t('label.lbl_arrivalScheduleDate')"
                          @change="changeDate"
                          :rules="[rules.yyyymmdd]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          :nudge-right="-100"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          class="date-calendar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" text
                              ><v-icon>mdi-calendar</v-icon></v-btn
                            >
                          </template>
                          <v-date-picker
                            v-model="arrivalScheduleDateCal"
                            @input="dateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- プルダウン2 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_xxxxxxxxx2") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          v-model="xxxxxxxxx2Selected"
                          :items="dummy_xxx2List"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
                <!-- テキストボックス2 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy2") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy2"
                          maxlength="10"
                          placeholder="文字を入力してください2"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
                <!-- テキストボックス3 -->
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="2">
                        <div class="register-label">
                          {{ $t("label.lbl_yyyyyyyyy3") }}<span class="require">*</span>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          solo
                          v-model="txt_yyyyyyyyy3"
                          maxlength="10"
                          placeholder="文字を入力してください3"
                        />
                      </v-col>
                      <v-col cols="2" />
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- ボタン -->
            <v-container>
              <v-row>
                <!--戻る -->
                <v-col>
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="clear">
                    {{ $t("btn.btn_back") }}
                  </v-btn>
                </v-col>
                <!--登録 -->
                <v-col>
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="e1 = 1">
                    {{ $t("btn.btn_insert") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
//import { appConfig } from "../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
//import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
//import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
export default {
  name: "register2",
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    e1: 1,
    // カレンダー
    arrivalScheduleDateCal: "",
    // XXXXX日付
    xxxxxxxDate: "",
    // プルダウン1
    xxxxxxxxx1Selected: "all",
    // プルダウン2
    xxxxxxxxx2Selected: "all",
    // メニュー
    openMenu: null,
    // テキストボックス1
    txt_yyyyyyyyy1: "",
    // テキストボックス2
    txt_yyyyyyyyy2: "",
    // テキストボックス3
    txt_yyyyyyyyy3: "",
    // カレンダー
    dateMenu: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    inputList: [],
    dummy_xxx1List: [
      { text: "ALL", value: "all" },
      { text: "プルダウンA1", value: "supplier1" },
      { text: "プルダウンA2", value: "supplier2" },
      { text: "プルダウンA3", value: "supplier3" },
      { text: "プルダウンA4", value: "supplier4" },
      { text: "プルダウンA5", value: "supplier5" },
    ],
    dummy_xxx2List: [
      { text: "ALL", value: "all" },
      { text: "プルダウンB1", value: "arrival1" },
      { text: "プルダウンB2", value: "arrival2" },
      { text: "プルダウンB3", value: "arrival3" },
      { text: "プルダウンB4", value: "arrival4" },
      { text: "プルダウンB5", value: "arrival5" },
    ],

    rules: {
      yyyymmdd: (value) => dateTimeHelper.validDate(value) || "日付が間違っています",
    },
  }),
  methods: {
    // 初期化
    init() {
      this.xxxxxxxxx2Selected = "all";
      this.xxxxxxxxx1Selected = "all";
      this.txt_xxxxxxxxx1 = "";
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      return dt.toISOString().substr(0, 10);
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    changeDate() {
      if (this.xxxxxxxDate.length == 8) {
        var dt = this.xxxxxxxDate;
        this.xxxxxxxDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      dateTimeHelper.validDate(this.xxxxxxxDate);
    },
    formValidation() {
      this.$refs.searchForm.validate();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal: function () {
      var aa = this.dateCalc(dateTimeHelper.convertJstDateTime(this.arrivalScheduleDateCal));
      this.xxxxxxxDate = this.formatDate(aa);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  #lbl-screen-name {
    padding: 0.1rem 0 0 0.5rem;
    font-size: x-large;
    font-weight: bold;
    display: inline-block;
  }
  .right {
    text-align: right;
  }
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
.v-btn.v-btn--has-bg {
  background: $color-obj-base;
  &.api-btn {
    width: 7rem;
  }
}

button {
  &.v-btn {
    &.day-ctrl-btn {
      padding: 0;
      width: 2rem;
      min-width: 2.5rem;
    }
    &.day-ctrl-now-btn {
      padding: 0;
      width: 4rem;
      min-width: 4rem;
    }
  }
}
.list-single ::v-deep {
  div.v-input__slot {
    border-radius: 0.4rem;
  }
}
.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}
.list-single ::v-deep,
.txt-single ::v-deep {
  z-index: 50;
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}
#listData {
  &.v-data-table--fixed-header ::v-deep {
    th {
      background: #82b1ff;
    }
  }
}
</style>
